<template>
  <div class="newfooter ff1">
    <div class="hbook">
      <div class="hb-t1">Book your<br />session today!</div>
      <div class="hb-services">
        <div class="hbs-item">thainess oil treatment</div>
        <div class="hbs-item">thai traditional treatment</div>
        <div class="hbs-item">foot treatment</div>
        <div class="hbs-item">and more!</div>
      </div>
      <div class="hb-more">
        <a
          href="https://book.healthland.com.my/booking/selectshop"
          class="hbtn2"
          >book now!</a
        >
      </div>
    </div>
    <div class="hfooter">
      <div class="hf-box">
        <div class="hf-logo">
          <img src="@/assets/images/logo.png" />
        </div>
        <div class="hf-addr">
          <div class="c">healthland group of companies</div>
          <div class="a">
            No 5, Lorong Desa Maju, Taman Desa, 58100 Kuala Lumpur
          </div>
        </div>
        <div class="hf-social">
          <a href="mailto:info@healthland.com.my" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 45 45"
            >
              <g
                id="Group_267"
                data-name="Group 267"
                transform="translate(-1540 -7713)"
              >
                <circle
                  id="Ellipse_57"
                  data-name="Ellipse 57"
                  cx="22.5"
                  cy="22.5"
                  r="22.5"
                  transform="translate(1540 7713)"
                  fill="#7910a1"
                />
                <path
                  id="Icon_material-email"
                  data-name="Icon material-email"
                  d="M21.309,6H5.034A2.032,2.032,0,0,0,3.01,8.034L3,20.241a2.04,2.04,0,0,0,2.034,2.034H21.309a2.04,2.04,0,0,0,2.034-2.034V8.034A2.04,2.04,0,0,0,21.309,6Zm0,4.069-8.137,5.086L5.034,10.069V8.034l8.137,5.086,8.137-5.086Z"
                  transform="translate(1549.328 7721.362)"
                  fill="#fff"
                />
              </g>
            </svg>
          </a>
          <a href="https://m.me/my.HealthLand/" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 45 45"
            >
              <g
                id="Group_268"
                data-name="Group 268"
                transform="translate(-1605 -7713)"
              >
                <circle
                  id="Ellipse_57"
                  data-name="Ellipse 57"
                  cx="22.5"
                  cy="22.5"
                  r="22.5"
                  transform="translate(1605 7713)"
                  fill="#7910a1"
                />
                <path
                  id="Icon_simple-messenger"
                  data-name="Icon simple-messenger"
                  d="M0,10.721C0,4.559,4.826,0,11.052,0S22.105,4.559,22.105,10.721,17.279,21.443,11.052,21.443a12.063,12.063,0,0,1-3.2-.424.884.884,0,0,0-.589.046l-2.2.967a.884.884,0,0,1-1.243-.783l-.064-1.971a.893.893,0,0,0-.295-.626A10.491,10.491,0,0,1,0,10.721ZM7.663,8.7,4.421,13.862a.519.519,0,0,0,.755.691l3.491-2.643a.65.65,0,0,1,.8,0l2.579,1.934a1.663,1.663,0,0,0,2.395-.442l3.242-5.158a.52.52,0,0,0-.755-.691L13.438,10.2a.637.637,0,0,1-.792,0L10.067,8.262a1.658,1.658,0,0,0-2.4.442Z"
                  transform="translate(1616.448 7724.445)"
                  fill="#fff"
                />
              </g>
            </svg>
          </a>
          <a href="https://www.facebook.com/my.HealthLand/" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 45 45"
            >
              <g
                id="Group_245"
                data-name="Group 245"
                transform="translate(-39 -5043)"
              >
                <circle
                  id="Ellipse_57"
                  data-name="Ellipse 57"
                  cx="22.5"
                  cy="22.5"
                  r="22.5"
                  transform="translate(39 5043)"
                  fill="#7910a1"
                />
                <path
                  id="Icon_awesome-facebook-f"
                  data-name="Icon awesome-facebook-f"
                  d="M12.843,12.626l.623-4.062h-3.9V5.928a2.031,2.031,0,0,1,2.29-2.195h1.772V.274A21.609,21.609,0,0,0,10.486,0C7.276,0,5.178,1.946,5.178,5.468v3.1H1.609v4.062H5.178v9.82H9.569v-9.82Z"
                  transform="translate(54.044 5054.223)"
                  fill="#fff"
                />
              </g>
            </svg>
          </a>
          <a href="https://www.instagram.com/my.healthland/" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 45 45"
            >
              <g
                id="Group_246"
                data-name="Group 246"
                transform="translate(-104 -5043)"
              >
                <circle
                  id="Ellipse_56"
                  data-name="Ellipse 56"
                  cx="22.5"
                  cy="22.5"
                  r="22.5"
                  transform="translate(104 5043)"
                  fill="#7910a1"
                />
                <path
                  id="Icon_awesome-instagram"
                  data-name="Icon awesome-instagram"
                  d="M9.776,7a5.013,5.013,0,1,0,5.013,5.013A5.005,5.005,0,0,0,9.776,7Zm0,8.273a3.259,3.259,0,1,1,3.259-3.259A3.265,3.265,0,0,1,9.776,15.274ZM16.163,6.8a1.169,1.169,0,1,1-1.169-1.169A1.167,1.167,0,0,1,16.163,6.8Zm3.32,1.187a5.787,5.787,0,0,0-1.579-4.1,5.825,5.825,0,0,0-4.1-1.579c-1.614-.092-6.453-.092-8.067,0a5.816,5.816,0,0,0-4.1,1.575,5.806,5.806,0,0,0-1.579,4.1c-.092,1.614-.092,6.453,0,8.067a5.787,5.787,0,0,0,1.579,4.1,5.832,5.832,0,0,0,4.1,1.579c1.614.092,6.453.092,8.067,0a5.787,5.787,0,0,0,4.1-1.579,5.825,5.825,0,0,0,1.579-4.1c.092-1.614.092-6.449,0-8.063Zm-2.086,9.8a3.3,3.3,0,0,1-1.859,1.859c-1.287.51-4.341.393-5.764.393s-4.481.113-5.764-.393a3.3,3.3,0,0,1-1.859-1.859c-.51-1.287-.393-4.341-.393-5.764s-.113-4.481.393-5.764A3.3,3.3,0,0,1,4.012,4.392C5.3,3.882,8.353,4,9.776,4s4.481-.113,5.764.393A3.3,3.3,0,0,1,17.4,6.251c.51,1.287.393,4.341.393,5.764S17.909,16.5,17.4,17.778Z"
                  transform="translate(116.644 5053.432)"
                  fill="#fff"
                />
              </g>
            </svg>
          </a>
        </div>
      </div>
      <div class="copyright">
        Copyright © 2025 Health Lega (HQ) Sdn Bhd. All Right Reserved.
      </div>
    </div>
  </div>
</template>
